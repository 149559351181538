<template>
  <div class="home-container">
    <section class="home-hero">
      <video src="https://assets.lightbeam.co.nz/LightBeam video (1080p).mp4" loop muted poster="/hero-bg.png"
        preload="auto" autoplay playsinline class="home-video"></video>
      <header data-thq="thq-navbar" class="home-navbar">
        <img alt="image" src="/light%20beam%20broadband.svg" class="home-branding" />
        <div data-thq="thq-burger-menu" class="home-burger-menu" data-role="show-signup">
          <header class="home-hamburger">
            <img alt="image" src="/hamburger.svg" class="home-icon" />
          </header>
        </div>
        <div data-thq="thq-mobile-menu" class="home-mobile-menu" data-role="signup-box-shower">
          <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="home-nav">
            <div class="home-container01">
              <img alt="image" src="/light%20beam%20broadband.svg" class="home-image" />
              <div data-thq="thq-close-menu" class="home-menu-close">
                <svg viewBox="0 0 1024 1024" class="home-icon1" data-role="x-close-signup-box">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="home-container02">
              <a href="https://lightbeam.outseta.com/auth?widgetMode=login#o-anonymous" class="home-login button">
                Login
              </a>
              <a href="https://lightbeam.outseta.com/auth?widgetMode=register#o-anonymous" target="_blank"
                rel="noreferrer noopener" class="home-register button">
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <div class="home-hero-content">
        <div class="home-header-container">
          <div class="home-header">
            <h1 class="home-heading">
              <span>
                Face-to-face with
                <span v-html="raw8xd0"></span>
              </span>
              <span class="home-text001">imagination</span>
              <br />
            </h1>
          </div>
          <p class="home-caption">
            Bring Your Own Router for Simple Easy No Contract High Speed Fibre
            Solutions
          </p>
        </div>
        <a href="#Pricingsec" class="home-link button">
          <span>
            <span>Get started today✨ </span>
            <br />
          </span>
        </a>
      </div>
    </section>
    <section class="home-growth">
      <div class="home-content">
        <div class="home-header01">
          <div class="home-header02">
            <h2 class="home-heading1">
              <span>
                Fibre Solutions optimised for
                <span v-html="rawkpjl"></span>
              </span>
              <span class="home-text007">you!</span>
              <br />
            </h2>
          </div>
          <p class="home-caption1">
            Whether you&apos;re a home user, startup or business owner, seeking
            Fibre solutions-
            <span v-html="raw3ann"></span>
          </p>
          <p class="home-caption2">
            LightBeam Fibre is your trusted partner for seamless online
            experiences.
          </p>
        </div>
        <div class="home-testimonial">
          <div class="home-content01">
            <span class="home-text009">Testimonial</span>
            <p class="home-text010">
              “I couldn&apos;t be happier with my experience with LightBeam Fibre!
              As a small business owner, seamless online experiences are crucial,
              and LightBeam Fibre has proven to be the perfect partner. From
              installation to ongoing support, their team has been responsive and
              reliable. I highly recommend LightBeam Fibre A+ service all around!”
            </p>
          </div>
          <div class="home-information">
            <div class="home-author">
              <span class="home-name">Ben | Bay of Plenty</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home-images">
        <div class="home-square"></div>
        <img alt="image" src="/light%20beam%20broadband.svg" class="home-image1" />
      </div>
    </section>
    <section class="home-note">
      <div class="home-steps">
        <h1 class="home-text011">
          <span class="home-text012">
            SWITCHING PROVIDERS HAS NEVER BEEN EASIER
          </span>
          <br />
        </h1>
        <div class="home-container03">
          <div class="home-container04">
            <div class="home-step">
              <h1 class="home-text014"><span>1</span></h1>
              <h1 class="home-text016">Pay</h1>
              <span class="home-text017">
                When you sign up, simply pre-pay the first month in advance.
              </span>
            </div>
            <div class="home-step1">
              <h1 class="home-text018"><span>2</span></h1>
              <h1 class="home-text020">Switch</h1>
              <span class="home-text021">
                <span>
                  Once you&apos;ve signed up, we&apos;ll raise the order to
                  transfer your existing service from your old provider.
                </span>
                <br />
              </span>
            </div>
            <div class="home-step2">
              <h1 class="home-text024">3</h1>
              <h1 class="home-text025">LightBeam</h1>
              <span class="home-text026">
                <span>
                  That&apos;s it! You&apos;re connected to LightBeam!
                  <span v-html="rawdaax"></span>
                </span>
                <br />
              </span>
            </div>
          </div>
          <div class="home-container05"></div>
        </div>
      </div>
    </section>
    <section class="home-note1">
      <div class="home-pricing">
        <div id="Pricingsec" class="home-container06">
          <div class="home-pricing-card">
            <span class="home-text029">FIBRE 50 🏎️</span>
            <div class="home-container07">
              <span class="home-text030">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-text033">59.99</span>
              <span class="home-text034"><span>/ monthly</span></span>
            </div>
            <span class="home-text036">Billed monthly + $23.99 setup fee</span>
            <div class="home-container08">
              <span class="home-text037">✔ Bring Your Own Router</span>
              <span class="home-text038">✔ Speeds up to 50/10 Mbps</span>
              <span class="home-text039">✔ Unlimited data</span>
              <span class="home-text040">
                <span>✔ Exclusive availability on the Chorus fibre network</span>
                <br />
              </span>
              <span class="home-text043">✔ Optional Static IP address</span>
            </div>
            <span class="home-text044">
              <span>Static IP address Available for $9.99/monthly </span>
              <br />
            </span>
            <a href="https://lightbeam.outseta.com/auth?widgetMode=register&amp;planUid=j9brlv9n&amp;planPaymentTerm=month&amp;skipPlanOptions=false#o-anonymous"
              class="home-link1 button">
              Signup Now
            </a>
          </div>
          <div class="home-pricing-card1">
            <span class="home-text047">FIBRE 950 🚀</span>
            <div class="home-container09">
              <span class="home-text048">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-text051">98.99</span>
              <span class="home-text052"><span>/ monthly</span></span>
            </div>
            <span class="home-text054">Billed monthly + ✨FREE setup ✨</span>
            <div class="home-container10">
              <span class="home-text055">✔ Bring Your Own Router</span>
              <span class="home-text056">✔ Priority support</span>
              <span class="home-text057">
                ✔ Blazing speeds up to 900/500 Mbps
              </span>
              <span class="home-text058">
                ✔ Unlimited data for uninterrupted online experiences
              </span>
              <span class="home-text059">
                ✔ Accessible on all national fibre networks
              </span>
              <span class="home-text060">✔ Optional Static IP address</span>
            </div>
            <span class="home-text061">
              <span>Static IP address Available for $4.99/monthly </span>
              <br />
            </span>
            <a href="https://lightbeam.outseta.com/auth?widgetMode=register&amp;planUid=LmJblpmP&amp;planPaymentTerm=month&amp;skipPlanOptions=false#o-anonymous"
              id="upgrade" target="_blank" rel="noreferrer noopener" class="home-link2 button">
              Upgrade Now ✨
            </a>
          </div>
          <div class="home-pricing-card2">
            <span class="home-text064">FIBRE 300 ✈️</span>
            <div class="home-container11">
              <span class="home-text065">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-text068">85.99</span>
              <span class="home-text069"><span>/ monthly</span></span>
            </div>
            <span class="home-text071">
              <span>Billed monthly + $19.99 setup fee</span>
              <span> </span>
              <br />
            </span>
            <div class="home-container12">
              <span class="home-text075">✔ Bring Your Own Router</span>
              <span class="home-text076">✔ Priority support</span>
              <span class="home-text077">✔ speeds up to 300/100 Mbps</span>
              <span class="home-text078">✔ Unlimited data</span>
              <span class="home-text079">
                ✔ Accessible on all national fibre networks
              </span>
              <span class="home-text080">
                ✔ Optional Static IP address
                <span v-html="rawor5n"></span>
              </span>
            </div>
            <span class="home-text081">
              <span>Static IP address Available for $7.99/monthly </span>
              <br />
            </span>
            <a href="https://lightbeam.outseta.com/auth?widgetMode=register&amp;planUid=zWZr8vWp&amp;planPaymentTerm=month&amp;skipPlanOptions=false#o-anonymous"
              target="_blank" rel="noreferrer noopener" class="home-link3 button">
              Connect Now
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="home-slider">
      <div class="home-header03">
        <h2 class="home-heading2">
          <span>Need something a bit more </span>
          <span class="home-text085">o</span>
          <span class="home-text086">ptimised for SPEED</span>
          <span>?</span>
        </h2>
      </div>
    </section>
    <h2 class="home-heading3">✨Introducing LightBeam Hyper Fibre✨</h2>
    <section class="home-experience">
      <div class="home-container13">
        <img alt="image" src="/light%20beam%20broadband.svg" class="home-from" />
        <div class="home-container14">
          <div class="home-checkmarks">
            <div class="home-check">
              <div class="home-mark">
                <svg viewBox="0 0 1024 1024" class="home-icon3">
                  <path
                    d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z">
                  </path>
                </svg>
              </div>
              <span class="home-text088">Speeds up to 4000/4000 Mbps</span>
            </div>
            <div class="home-check1">
              <div class="home-mark1">
                <svg viewBox="0 0 1024 1024" class="home-icon5">
                  <path
                    d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z">
                  </path>
                </svg>
              </div>
              <span class="home-text089">
                Unlimited data for the ultimate online experience
              </span>
            </div>
            <div class="home-check2">
              <div class="home-mark2">
                <svg viewBox="0 0 1024 1024" class="home-icon7">
                  <path
                    d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z">
                  </path>
                </svg>
              </div>
              <span class="home-text090">Bring Your Own (B.Y.O.) Router</span>
            </div>
            <div class="home-check3"></div>
          </div>
          <div class="home-images1">
            <div class="home-square1"></div>
            <img alt="image" src="/experience-600w.png" class="home-image2" />
          </div>
        </div>
      </div>
      <div class="home-content02">
        <div class="home-header04">
          <div class="home-header-container1">
            <div class="home-header05">
              <h2 class="home-heading4">
                <span>A world class </span>
                <span class="home-text092">experience</span>
              </h2>
            </div>
            <p class="home-caption3">
              <span class="home-text093">
                BEFORE YOU ORDER: We need to set some expectations about
                HyperFibre.
              </span>
              <br class="home-text094" />
              <br class="home-text095" />
              <br class="home-text096" />
              <br class="home-text097" />
              <span class="home-text098">
                HyperFibre provides speeds up to a whopping 4000Mbps and requires
                either a Chorus ONT upgrade and/or a Router capable of handling
                these speeds.
                <span v-html="rawlyyk"></span>
              </span>
              <br class="home-text099" />
              <br class="home-text100" />
              <span class="home-text101">
                You won&apos;t see those speeds on your end devices unless your
                hardware and the source of the data supports it. Ideal for...
                bragging rights, maybe?
              </span>
            </p>
          </div>
        </div>
        <div class="home-testimonial1">
          <div class="home-information1"></div>
        </div>
      </div>
    </section>
    <section class="home-note2">
      <div class="home-pricing1">
        <div id="Pricing" class="home-container15">
          <div class="home-pricing-card3">
            <span class="home-text102">HyperFibre 4000 🚀</span>
            <div class="home-container16">
              <span class="home-text103">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-text106">199.99</span>
              <span class="home-text107"><span>/ monthly</span></span>
            </div>
            <span class="home-text109">Billed monthly + ✨FREE setup ✨</span>
            <div class="home-container17">
              <span class="home-text110">✔ Bring Your Own Router</span>
              <span class="home-text111">✔ Priority support</span>
              <span class="home-text112">
                ✔ Lightning Speeds up to 4000/4000 Mbps
              </span>
              <span class="home-text113">
                ✔ Unlimited data for uninterrupted online experiences
              </span>
              <span class="home-text114">✔ Free Static IP address</span>
            </div>
            <span class="home-text115">
              <span>Static IP address Available for Free </span>
              <br />
            </span>
            <a href="https://lightbeam.outseta.com/auth?widgetMode=register&amp;planUid=OW4r1GQg&amp;planPaymentTerm=month&amp;skipPlanOptions=false#o-anonymous"
              id="upgrade" target="_blank" rel="noreferrer noopener" class="home-link4 button">
              Upgrade Now ✨
            </a>
          </div>
          <div class="home-pricing-card4">
            <span class="home-text118">HyperFibre 2000 ✈️</span>
            <div class="home-container18">
              <span class="home-text119">
                <span>$</span>
                <span></span>
              </span>
              <span class="home-text122">149.99</span>
              <span class="home-text123"><span>/ monthly</span></span>
            </div>
            <span class="home-text125">
              <span>Billed monthly + $19.99 setup fee</span>
              <span> </span>
              <br />
            </span>
            <div class="home-container19">
              <span class="home-text129">✔ Bring Your Own Router</span>
              <span class="home-text130">✔ Priority support</span>
              <span class="home-text131">✔ Speeds up to 2000/2000 Mbps</span>
              <span class="home-text132">✔ Unlimited data</span>
              <span class="home-text133">
                ✔ Accessible on all national fibre networks
              </span>
              <span class="home-text134">
                ✔ Optional Static IP address
                <span v-html="raw4xyp"></span>
              </span>
            </div>
            <span class="home-text135">
              <span>Static IP address Available for $7.99/monthly </span>
              <br />
            </span>
            <a href="https://lightbeam.outseta.com/auth?widgetMode=register&amp;planUid=XQYr149P&amp;planPaymentTerm=month&amp;skipPlanOptions=false#o-anonymous"
              target="_blank" rel="noreferrer noopener" class="home-link5 button">
              Connect Now
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="home-note3">
      <h2 class="home-caption4">
        Experience the symphony of seamless connectivity with fibre broadband,
        where every data stream becomes a melody of speed and reliability,
        harmonizing the digital notes of your online journey.
      </h2>
    </section>
    <section class="home-faq">
      <div class="home-header06">
        <h2 class="home-heading5">
          <span>Frequently asked </span>
          <span class="home-text139">questions</span>
          <span><span v-html="rawx9k7"></span></span>
        </h2>
      </div>
      <div class="home-content03">
        <div class="home-column">
          <div class="home-element">
            <h3 class="home-header07">
              How does the service transfer process work after signing up with
              LightBeam?
            </h3>
            <p class="home-content04">
              Once you&apos;ve completed the sign-up process, we initiate the
              order to transfer your existing service from your previous provider
              seamlessly.
            </p>
          </div>
          <div class="home-element01">
            <h3 class="home-header08">
              How long does the transfer usually take?
            </h3>
            <p class="home-content05">
              Transfers can occur as swiftly as 1 business day. However, we
              recommend checking the terms of your current provider, especially if
              you&apos;re in a contract or need to provide them with a 30-day
              notice.
            </p>
          </div>
          <div class="home-element02">
            <h3 class="home-header09">
              How can I check if I&apos;m in a Fibre coverage area with LightBeam?
            </h3>
            <p class="home-content06">
              Simply open a live chat with us, and we&apos;ll promptly inform you
              about the available Fibre coverage in your area. Our team is here to
              assist you with any inquiries regarding service availability.
            </p>
          </div>
          <div class="home-element03">
            <h3 class="home-header10">
              Will I see the full 4000Mbps speed on my devices?
            </h3>
            <p class="home-content07">
              Not necessarily. While HyperFibre provides incredible speeds, the
              actual speed you experience on your devices is contingent on the
              capabilities of your hardware and the source of the data.
            </p>
          </div>
          <div class="home-element04">
            <h3 class="home-header11">Is HyperFibre necessary for everyone?</h3>
            <p class="home-content08">
              No, HyperFibre is not a necessity for everyone. It&apos;s an option
              for those who specifically desire ultra-high-speed internet, but it
              might be more than what the average user requires.
            </p>
          </div>
        </div>
        <div class="home-column1">
          <div class="home-element05">
            <h3 class="home-header12">
              What if I don&apos;t currently have fibre?
            </h3>
            <p class="home-content09">
              No worries! If you don&apos;t have fibre yet, we&apos;ll promptly
              submit the order to get fibre installed at your location. If fibre
              is available in your region.
            </p>
          </div>
          <div class="home-element06">
            <h3 class="home-header13">
              Is there any concern about being locked into contracts with
              LightBeam?
            </h3>
            <p class="home-content10">
              Absolutely not! LightBeam doesn&apos;t bind you with any sneaky fish
              hooks. We believe in providing flexibility and transparency in our
              service.
            </p>
          </div>
          <div class="home-element07">
            <h3 class="home-header14">
              Why consider HyperFibre, and who is it suitable for?
            </h3>
            <p class="home-content11">
              HyperFibre, offering speeds up to 4000Mbps, is an option for those
              seeking unparalleled speeds. It&apos;s perfect for those who
              prioritize high-performance internet, perhaps even for bragging
              rights!
            </p>
          </div>
          <div class="home-element08">
            <h3 class="home-header15">
              What should I be aware of before ordering HyperFibre?
            </h3>
            <p class="home-content12">
              Before ordering, it&apos;s essential to set expectations. HyperFibre
              requires a Chorus ONT upgrade and/or a Router capable of handling
              these speeds. Keep in mind that achieving the full 4000Mbps on your
              end devices depends on your hardware and the source of the data.
            </p>
          </div>
          <div class="home-element09">
            <h3 class="home-header16">
              For more FAQ&apos;s see our Knowledge Base or open a live chat
              request.
            </h3>
            <a href="https://help.lightbeam.co.nz/support/kb/categories" target="_blank" rel="noreferrer noopener"
              class="home-link6 button">
              <span>
                <span>Knowledge Base</span>
                <br />
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="home-create">
      <div class="home-content13">
        <div class="home-header17">
          <h2 class="home-heading6">
            <span>Need </span>
            <span class="home-text145">Support</span>
            <span>!?</span>
            <br />
          </h2>
        </div>
        <div class="home-container20">
          <div class="home-container21">
            <div class="home-container22">
              <DangerousHTML html="<div id='support-form'></div>
  <script>
  var o_options = {
      domain: 'lightbeam.outseta.com',
      load: 'support',
      support: {
          mode: 'embed',
          selector: '#support-form'
      }
  };
  </script>
  <script src='https://cdn.outseta.com/outseta.min.js'
          data-options='o_options'>
  </script>"></DangerousHTML>
            </div>
          </div>
        </div>
        <a href="https://help.lightbeam.co.nz/support/kb/categories" target="_blank" rel="noreferrer noopener"
          class="home-link7 button">
          <span>
            <span>Knowledge Base</span>
            <br />
          </span>
        </a>
      </div>
    </section>
    <div class="home-footer">
      <div class="home-content14">
        <div class="home-main">
          <div class="home-branding1">
            <img alt="image" src="/light%20beam%20broadband.svg" class="home-image3" />
            <span class="home-text151">
              <span>
                Experience lightning-fast internet with Lightbeam NZ. We offer
                reliable fiber broadband services across New Zealand, ensuring
                seamless connectivity for homes and businesses.
                <span v-html="rawfd5q"></span>
              </span>
              <br />
              <br />
              <span>
                Elevate your online experience with Lightbeam&apos;s high-speed
                internet solutions.
                <span v-html="raw1yv2"></span>
              </span>
            </span>
          </div>
          <div class="home-links"></div>
        </div>
        <div class="home-bottom">
          <span class="home-text156">
            © 2022 LightBeam Fibre - All rights reserved
          </span>
          <a href="#Pricing" data-role="scroll-top" class="home-link8 button">
            <img alt="image" src="/arrow.svg" class="home-image4" />
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="home-container24">
        <DangerousHTML html="<script>
        var signupShow=false;
  document.addEventListener('DOMContentLoaded', function() {
    console.log('This is a test');
    // get the element with the 'scroll-top' data-role
    const scrollTopBtn = document.querySelector('[data-role=scroll-top]');
    const showMenuBtn = document.querySelector('[data-role=show-signup]');
    const signupBox = document.querySelector('[data-role=signup-box-shower]');
    const xCloseBox = document.querySelector('[data-role=x-close-signup-box]');
  console.log(xCloseBox)
    console.log(signupBox)
  console.log(scrollTopBtn)
    // when the element is clicked
    scrollTopBtn.addEventListener('click', function() {
      // smoothly scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
    xCloseBox.addEventListener('click', function() {
      console.log('This is a test');
      signupBox.classList.remove('teleport-show');
      signupBox.classList.remove('thq-show');
      signupBox.classList.remove('thq-translate-to-default');
      signupBox.style.transform = 'translateX(100%)';
      signupShow=false;
    });
    showMenuBtn.addEventListener('click', function() {
    console.log('This is a test');
    
      //thq-mobile-menu
      // smoothly scroll to the top of the page
    if(signupShow){
      signupBox.classList.remove('teleport-show');
      signupBox.classList.remove('thq-show');
      signupBox.classList.remove('thq-translate-to-default');
      signupBox.style.transform = 'translateX(100%)';
      signupShow=false;
    }else{
      signupBox.classList.add('teleport-show');
      signupBox.classList.add('thq-show');
      signupBox.classList.add('thq-translate-to-default');
      signupBox.style.transform = 'translateX(0%)';
      signupShow=true;
      }
      
      
    });
  });
  </script>
  "></DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

export default {
  name: 'Home',
  components: {
    DangerousHTML,
  },
  data() {
    return {
      raw8xd0: ' ',
      rawkpjl: ' ',
      raw3ann: ' ',
      rawdaax: ' ',
      rawor5n: ' ',
      rawlyyk: ' ',
      raw4xyp: ' ',
      rawx9k7: ' ',
      rawfd5q: ' ',
      raw1yv2: ' ',
    }
  },
  metaInfo: {
    title: 'Lightbeam NZ | Exciting High-Speed Fiber Broadband Services',
    meta: [
      {
        name: 'description',
        content:
          'Plans From $59.99 | Experience lightning-fast internet with Lightbeam. We offer reliable fiber broadband services across NZ | Hyper Fibre & Static IP Available',
      },
      {
        property: 'og:title',
        content: 'Lightbeam NZ | Exciting High-Speed Fiber Broadband Services',
      },
      {
        property: 'og:description',
        content:
          'Plans From $59.99 | Experience lightning-fast internet with Lightbeam. We offer reliable fiber broadband services across NZ | Hyper Fibre & Static IP Available',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/074ede81-83b5-4ca9-92ab-436d78bb8d43/657617c8-9a68-48ef-b34d-e404f22fd161?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}

.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}

.home-video {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.home-navbar {
  width: 100%;
  height: 101px;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}

.home-branding {
  width: 144px;
  height: 100%;
  object-fit: cover;
}

.home-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-hamburger {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #0000F5;
}

.home-icon {
  width: 60px;
  object-fit: cover;
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}

.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.home-image {
  height: 8rem;
}

.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.home-icon1 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
}

.home-container02 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}

.home-login {
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}

.home-register {
  text-decoration: none;
}

.home-hero-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 557px;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header-container {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading {
  color: rgb(255, 255, 255);
  font-size: 80px;
  max-width: 790px;
  font-style: normal;
  font-weight: 500;
}

.home-text001 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}

.home-caption {
  color: rgb(255, 255, 255);
  height: 59px;
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}

.home-link {
  text-decoration: none;
}

.home-growth {
  width: 100%;
  height: 989px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}

.home-content {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header02 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading1 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}

.home-text007 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}

.home-caption1 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}

.home-caption2 {
  color: #ffffff;
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
  border-color: #313133;
  padding-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 1px;
}

.home-testimonial {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-content01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text009 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}

.home-text010 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}

.home-information {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-author {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}

.home-name {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}

.home-images {
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}

.home-square {
  top: 64px;
  right: -35px;
  width: 180px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #0000FF;
}

.home-image1 {
  width: 640px;
  z-index: 100;
  margin-top: var(--dl-space-space-sixunits);
  object-fit: cover;
}

.home-note {
  width: 100%;
  height: 360px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 45px;
  justify-content: flex-start;
  background-color: #000000;
}

.home-steps {
  width: 1186px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
}

.home-text011 {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}

.home-text012 {
  font-size: 2rem;
}

.home-container03 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-container04 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-step {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 255, 0);
}

.home-text014 {
  color: var(--dl-color-gray-900);
  font-size: 6rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}

.home-text016 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-halfunit);
}

.home-text017 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}

.home-step1 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}

.home-text018 {
  color: var(--dl-color-gray-900);
  font-size: 6rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}

.home-text020 {
  color: #fbfbfb;
  font-size: 3rem;
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-halfunit);
}

.home-text021 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}

.home-step2 {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}

.home-text024 {
  color: var(--dl-color-gray-900);
  font-size: 6rem;
  text-align: center;
  font-weight: 900;
  margin-bottom: -30px;
}

.home-text025 {
  color: #fbfbfb;
  font-size: 3rem;
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-halfunit);
}

.home-text026 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
}

.home-container05 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-note1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 113px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url('https://images.unsplash.com/photo-1624589010366-365b61efb41b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE3fHxuZXclMjB6ZWFsYW5kfGVufDB8fHx8MTcwMDkwMzI1MHww&ixlib=rb-4.0.3&h=1900');
  background-position: center;
}

.home-pricing {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-container06 {
  width: 1136px;
  height: 806px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: center;
}

.home-pricing-card {
  width: 100%;
  height: 556px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}

.home-pricing-card:hover {
  transform: scale(1.02);
}

.home-text029 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}

.home-container07 {
  width: 214px;
  height: 49px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text030 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text033 {
  font-size: 2.5rem;
  font-weight: 700;
}

.home-text034 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text036 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-text037 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text038 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text039 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text040 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text043 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text044 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-link1 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}

.home-pricing-card1 {
  width: 398px;
  height: 672px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  padding-right: 35px;
  flex-direction: column;
  padding-bottom: 36px;
  background-color: var(--dl-color-gray-white);
}

.home-pricing-card1:hover {
  transform: scale(1.02);
}

.home-text047 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}

.home-container09 {
  width: 287px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text048 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text051 {
  font-size: 4rem;
  font-weight: 700;
}

.home-text052 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text054 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-container10 {
  width: 305px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
}

.home-text055 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text056 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text057 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text058 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text059 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text060 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text061 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-link2 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}

.home-pricing-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-white);
}

.home-pricing-card2:hover {
  transform: scale(1.02);
}

.home-text064 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}

.home-container11 {
  width: 252px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text065 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text068 {
  font-size: 3rem;
  font-weight: 700;
}

.home-text069 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text071 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-container12 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
}

.home-text075 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text076 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text077 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text078 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text079 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text080 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text081 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-link3 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}

.home-slider {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}

.home-header03 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading2 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}

.home-text085 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}

.home-text086 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}

.home-heading3 {
  color: rgb(255, 255, 255);
  width: 924px;
  height: 105px;
  font-size: 45px;
  max-width: 900px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 400;
  padding-bottom: 5px;
}

.home-experience {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 10px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}

.home-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-from {
  width: 357px;
  height: 283px;
  object-fit: cover;
}

.home-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-checkmarks {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-check {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-mark {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}

.home-icon3 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}

.home-text088 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}

.home-check1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-mark1 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}

.home-icon5 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}

.home-text089 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}

.home-check2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-mark2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}

.home-icon7 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}

.home-text090 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}

.home-check3 {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-images1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-end;
}

.home-square1 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #0000FF;
}

.home-image2 {
  width: 575px;
  z-index: 100;
  object-fit: cover;
}

.home-content02 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header04 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}

.home-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header05 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-heading4 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}

.home-text092 {
  font-family: "Playfair Display";
}

.home-caption3 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}

.home-text094 {
  color: #7c7c80;
  font-size: 24px;
  line-height: 36px;
}

.home-text096 {
  color: #7c7c80;
  font-size: 24px;
  line-height: 36px;
}

.home-testimonial1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-information1 {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-note2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 113px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url('https://images.unsplash.com/photo-1515248027005-c33283ec3fba?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDc2fHxuZXclMjB6ZWFsYW5kfGVufDB8fHx8MTcwMDk2ODg2NHww&ixlib=rb-4.0.3&w=1400');
  background-position: center;
}

.home-pricing1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-container15 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: center;
}

.home-pricing-card3 {
  width: 438px;
  height: 633px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  padding-right: 35px;
  flex-direction: column;
  padding-bottom: 36px;
  background-color: var(--dl-color-gray-white);
}

.home-pricing-card3:hover {
  transform: scale(1.02);
}

.home-text102 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}

.home-container16 {
  width: 310px;
  height: 116px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text103 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text106 {
  font-size: 4rem;
  font-weight: 700;
}

.home-text107 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text109 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-container17 {
  width: 333px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
}

.home-text110 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text111 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text112 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text113 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text114 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text115 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-link4 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}

.home-pricing-card4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  min-height: 450px;
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-white);
}

.home-pricing-card4:hover {
  transform: scale(1.02);
}

.home-text118 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
}

.home-container18 {
  width: 270px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}

.home-text119 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text122 {
  font-size: 3rem;
  font-weight: 700;
}

.home-text123 {
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}

.home-text125 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-container19 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0px;
  flex-direction: column;
}

.home-text129 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text130 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text131 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text132 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text133 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text134 {
  margin-bottom: var(--dl-space-space-unit);
}

.home-text135 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-link5 {
  color: var(--dl-color-gray-white);
  margin-top: auto;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}

.home-note3 {
  width: 100%;
  height: 499px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}

.home-caption4 {
  color: rgb(124, 124, 128);
  font-size: 40px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-right: 158px;
}

.home-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 1715px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 36px;
}

.home-header06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-heading5 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}

.home-text139 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}

.home-content03 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.home-column {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-element {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header07 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content04 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element01 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header08 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content05 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element02 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header09 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content06 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element03 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content07 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element04 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header11 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content08 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-element05 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header12 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content09 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element06 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header13 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content10 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element07 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header14 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content11 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element08 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header15 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-content12 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}

.home-element09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.home-header16 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.home-link6 {
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}

.home-create {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}

.home-content13 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #0000F5;
}

.home-header17 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-heading6 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}

.home-text145 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}

.home-container20 {
  width: 100%;
  height: 787px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.home-container21 {
  width: 755px;
  height: 750px;
  padding: var(--dl-space-space-unit);
}

.home-container22 {
  display: contents;
}

.home-link7 {
  text-decoration: none;
  background-color: #000000;
}

.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}

.home-content14 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}

.home-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.home-branding1 {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-image3 {
  width: 120px;
  object-fit: cover;
}

.home-text151 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}

.home-links {
  gap: 120px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-text156 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}

.home-link8 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-image4 {
  width: 21px;
  object-fit: cover;
}

.home-container24 {
  display: contents;
}

@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }

  .home-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-growth {
    height: 1333px;
    flex-direction: column;
  }

  .home-testimonial {
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-text010 {
    max-width: 560px;
  }

  .home-images {
    padding-top: 0px;
  }

  .home-square {
    top: -20px;
    right: -20px;
  }

  .home-image1 {
    width: 438px;
    height: 512px;
    margin-top: 0px;
  }

  .home-note {
    height: 476px;
  }

  .home-steps {
    width: 990px;
  }

  .home-text011 {
    text-align: center;
  }

  .home-text014 {
    text-align: center;
  }

  .home-text016 {
    text-align: center;
  }

  .home-text018 {
    text-align: center;
  }

  .home-text020 {
    text-align: center;
  }

  .home-text024 {
    text-align: center;
  }

  .home-text025 {
    text-align: center;
  }

  .home-pricing {
    height: 822px;
  }

  .home-container06 {
    width: 283px;
    height: 785px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-pricing-card {
    width: 100%;
    height: 583px;
    padding: var(--dl-space-space-twounits);
    max-width: 100%;
    align-items: center;
  }

  .home-text033 {
    font-size: 2.2rem;
  }

  .home-text037 {
    font-size: 15px;
  }

  .home-text038 {
    font-size: 15px;
  }

  .home-text039 {
    font-size: 15px;
  }

  .home-text040 {
    font-size: 15px;
  }

  .home-text043 {
    font-size: 15px;
  }

  .home-pricing-card1 {
    width: 403px;
    height: 712px;
    max-width: 100%;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text047 {
    font-size: 1.7rem;
  }

  .home-container09 {
    width: 223px;
  }

  .home-text051 {
    font-size: 2.7rem;
  }

  .home-container10 {
    width: 100%;
  }

  .home-text055 {
    font-size: 15px;
  }

  .home-text056 {
    font-size: 15px;
  }

  .home-text057 {
    font-size: 15px;
  }

  .home-text058 {
    font-size: 15px;
  }

  .home-text059 {
    font-size: 15px;
  }

  .home-text060 {
    font-size: 15px;
  }

  .home-pricing-card2 {
    width: 318px;
    height: 660px;
    max-width: 100%;
    align-items: center;
  }

  .home-container11 {
    width: 220px;
  }

  .home-text068 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .home-text075 {
    font-size: 15px;
  }

  .home-text076 {
    font-size: 15px;
  }

  .home-text077 {
    font-size: 15px;
  }

  .home-text078 {
    font-size: 15px;
  }

  .home-text079 {
    font-size: 15px;
  }

  .home-text080 {
    font-size: 15px;
  }

  .home-experience {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .home-content02 {
    height: 676px;
  }

  .home-pricing-card3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }

  .home-pricing-card4 {
    width: 100%;
    max-width: 100%;
    align-items: center;
  }

  .home-faq {
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .home-content03 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }

  .home-column {
    max-width: 100%;
  }

  .home-header07 {
    font-size: 24px;
  }

  .home-content04 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header08 {
    font-size: 24px;
  }

  .home-content05 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header09 {
    font-size: 24px;
  }

  .home-content06 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header10 {
    font-size: 24px;
  }

  .home-content07 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header11 {
    font-size: 24px;
  }

  .home-content08 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-column1 {
    max-width: 100%;
  }

  .home-header12 {
    font-size: 24px;
  }

  .home-content09 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header13 {
    font-size: 24px;
  }

  .home-content10 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header14 {
    font-size: 24px;
  }

  .home-content11 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header15 {
    font-size: 24px;
  }

  .home-content12 {
    font-size: 14px;
    line-height: 21px;
  }

  .home-header16 {
    font-size: 24px;
  }

  .home-content13 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-heading6 {
    font-size: 50px;
  }

  .home-content14 {
    border-top-width: 0px;
  }

  .home-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .home-branding1 {
    width: 100%;
  }
}

@media(max-width: 767px) {
  .home-hero {
    height: 640px;
  }

  .home-video {
    height: 642px;
  }

  .home-burger-menu {
    display: flex;
  }

  .home-heading {
    font-size: 60px;
  }

  .home-growth {
    height: 1051px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-header01 {
    gap: var(--dl-space-space-unit);
  }

  .home-heading1 {
    font-size: 40px;
  }

  .home-caption1 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-caption2 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-text009 {
    font-size: 12px;
    line-height: 18px;
  }

  .home-name {
    font-size: 12px;
    line-height: 18px;
  }

  .home-image1 {
    width: 100%;
  }

  .home-note {
    height: 479px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-steps {
    width: 766px;
    align-self: center;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-container03 {
    flex-direction: column;
  }

  .home-step {
    padding: var(--dl-space-space-unit);
    align-self: center;
  }

  .home-step1 {
    padding: var(--dl-space-space-unit);
    align-self: center;
  }

  .home-step2 {
    padding: var(--dl-space-space-unit);
    align-self: center;
  }

  .home-note1 {
    height: 1839px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-container06 {
    width: 739px;
    height: 1786px;
    flex-flow: column;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-slider {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-heading2 {
    font-size: 40px;
  }

  .home-heading3 {
    width: 766px;
    font-size: 30px;
    text-align: center;
  }

  .home-experience {
    width: 100%;
    height: 1872px;
    padding-top: 22px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-container13 {
    height: 743px;
  }

  .home-from {
    width: auto;
    height: 100%;
  }

  .home-square1 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }

  .home-image2 {
    width: 100%;
  }

  .home-content02 {
    gap: var(--dl-space-space-oneandhalfunits);
    height: 341px;
  }

  .home-header04 {
    height: 429px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-header-container1 {
    gap: var(--dl-space-space-unit);
    height: 388px;
  }

  .home-heading4 {
    font-size: 40px;
  }

  .home-caption3 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text093 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text095 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text097 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text098 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text099 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text100 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text101 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-note2 {
    height: 1397px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-container15 {
    flex-flow: column;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-pricing-card3 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card4 {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }

  .home-note3 {
    height: 189px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-caption4 {
    font-size: 18px;
    max-width: 90%;
    line-height: 23px;
    margin-right: 0px;
  }

  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-header06 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }

  .home-heading5 {
    font-size: 36px;
  }

  .home-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-element {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header07 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element01 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header08 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element02 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header09 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element03 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header10 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element04 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header11 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-element05 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header12 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element06 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header13 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element07 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header14 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element08 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header15 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-element09 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }

  .home-header16 {
    font-size: 16px;
    line-height: 20px;
  }

  .home-create {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-content13 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading6 {
    font-size: 36px;
  }

  .home-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}

@media(max-width: 479px) {
  .home-hero {
    height: 590px;
  }

  .home-video {
    height: 590px;
  }

  .home-navbar {
    width: 372px;
  }

  .home-branding {
    padding-top: var(--dl-space-space-unit);
  }

  .home-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }

  .home-icon {
    width: 30px;
  }

  .home-mobile-menu {
    padding: 16px;
  }

  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
    width: 372px;
  }

  .home-heading {
    font-size: 36px;
  }

  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }

  .home-link {
    text-align: center;
  }

  .home-growth {
    height: 955px;
    padding-bottom: 61px;
  }

  .home-heading1 {
    font-size: 36px;
  }

  .home-images {
    height: 578px;
  }

  .home-image1 {
    width: 350px;
    height: 373px;
  }

  .home-note {
    height: 971px;
  }

  .home-steps {
    width: 478px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-text011 {
    width: 365px;
    margin: var(--dl-space-space-unit);
  }

  .home-container04 {
    width: 478px;
    flex-direction: column;
  }

  .home-step {
    width: 400px;
    align-self: center;
  }

  .home-step1 {
    width: 400px;
    align-self: center;
    margin-right: 0px;
  }

  .home-step2 {
    width: 400px;
    align-self: center;
  }

  .home-text024 {
    color: var(--dl-color-gray-900);
    font-size: 6rem;
    font-weight: 900;
  }

  .home-text025 {
    color: rgb(251, 251, 251);
    font-size: 3rem;
    font-weight: 500;
  }

  .home-text026 {
    color: var(--dl-color-gray-500);
    font-size: 0.75rem;
  }

  .home-container05 {
    flex-direction: column;
  }

  .home-note1 {
    height: 1886px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }

  .home-container06 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-pricing-card2 {
    margin-bottom: 0px;
  }

  .home-heading2 {
    font-size: 36px;
    line-height: 42px;
  }

  .home-heading3 {
    width: 368px;
    height: 134px;
    margin: var(--dl-space-space-unit);
    font-size: 36px;
    line-height: 42px;
    padding-right: 0px;
  }

  .home-container13 {
    width: 100%;
  }

  .home-heading4 {
    color: rgb(255, 255, 255);
  }

  .home-text092 {
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
  }

  .home-container15 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-container17 {
    width: 277px;
  }

  .home-pricing-card4 {
    margin-bottom: 0px;
  }

  .home-faq {
    height: 1942px;
  }

  .home-header06 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }

  .home-column1 {
    height: 746px;
  }

  .home-link6 {
    text-align: center;
  }

  .home-create {
    height: 1194px;
  }

  .home-content13 {
    height: 1055px;
  }

  .home-heading6 {
    color: rgb(255, 255, 255);
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }

  .home-link7 {
    text-align: center;
  }

  .home-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .home-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }

  .home-link8 {
    justify-content: center;
  }
}
</style>
